import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// Import React Router
import { RouterProvider } from "react-router-dom";
import router from './utils/router';

// Setup ANTD
import { ConfigProvider } from 'antd';
import AntdConfig from './utils/AntdConfig';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <ConfigProvider theme={AntdConfig.theme}>
                <RouterProvider router={router} />
        </ConfigProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
