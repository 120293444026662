import { Request, RequestUrlEncoded } from "./common"
import type { ApiResponseT } from "./common";

export const Login = async (data: { username: string, password: string }) => {
    const result = await RequestUrlEncoded('Account/Login', {
        method: 'POST',
        body: new URLSearchParams(data),
    })
    return result;
}

export const Logout = async () => {
    const result = await RequestUrlEncoded('Account/Logout', {
        method: 'POST',
        body: new URLSearchParams(),
    })
    return result;
}

export const GetCurrentUsername = async () => {
    const result = await Request('Account/GetCurrentUsername', {
        method: 'GET'
    })
    return result as [ApiResponseT<string> | null, Response];
}