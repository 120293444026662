import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";

export default function AppIndex() {
    const navigate = useNavigate();

    useEffect(()=> {
        setTimeout(() => {
            navigate("/Login")
        }, 1000);
    },[])
    return (
        <div className="AppIndex">
            Redirecting to JT Stocktake System...
        </div>
    );
};
