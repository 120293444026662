import { Outlet } from "react-router-dom";
import { Flex, App } from 'antd';
import SideBar from "./components/SideBar/SideBar";

import React, { useState } from 'react'
import type { RequestOptionsType } from '@ant-design/pro-components'

export interface TAppContext {
    factoriesBySupplier: RequestOptionsType[];
    setFactoriesBySupplier: React.Dispatch<React.SetStateAction<RequestOptionsType[]>>;

    projectIdsList: {
        label: string,
        value: string,
        title: string,
        key: string,
    }[];
    setProjectIdsList: React.Dispatch<React.SetStateAction<{
        label: string,
        value: string,
        title: string,
        key: string,
    }[]>>;
}

export const AppContext = React.createContext<TAppContext>({} as TAppContext);

const Application = () => {
    const [factoriesBySupplier, setFactoriesBySupplier] = useState<RequestOptionsType[]>([]);
    const [projectIdsList, setProjectIdsList] = useState<{
        label: string,
        value: string,
        title: string,
        key: string,
    }[]>([]);
    return (<App>
        <Flex>
            <div style={{ height: '100vh' }}>
                <SideBar></SideBar>
            </div>
            <div id="content-container">
                <AppContext.Provider value={{
                    factoriesBySupplier, setFactoriesBySupplier,
                    projectIdsList, setProjectIdsList
                }}>
                    <Outlet />
                </AppContext.Provider>
            </div>
        </Flex>
    </App>);
};

export default Application;
